import "./AddNew.css";
import { Button, TextField } from "@mui/material";
import { BristolChoice } from "./FormInputs/BristolChoice";
import { ColorChoice } from "./FormInputs/ColorChoice";
import PainLevel from "./FormInputs/PainLevel";
import { BooleanOption } from "./FormInputs/BooleanOption";
import { useState } from "react";
import { useEffect } from "react";
import ToggleSize from "./FormInputs/ToggleSize";

export const AddNew = ({ onClose, onSave }) => {
  const initialInput = {
    dateCreated: new Date().toISOString(),
    bristol: null,
    color: null,
    painLevel: 1,
    blood: false,
    foodVisible: false,
    badSmell: false,
    burning: false,
    size: "SMALL",
  };
  const [formData, setFormData] = useState(initialInput);

  const handleUpdate = (data) => {
    setFormData((prevValue) => {
      return { ...prevValue, ...data };
    });
    console.log(data);
  };

  const handleSave = () => {
    onSave(formData);
    onClose();
  };

  const isCompleted = () => {
    if (formData.bristol && formData.color) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <div className="add-new-movement">
      <h2>New Movement</h2>
      <TextField
        type="text"
        label="Time"
        value={initialInput.dateCreated}
        disabled
      />
      <BristolChoice onChange={(value) => handleUpdate({ bristol: value })} />
      <ColorChoice onChange={(value) => handleUpdate({ color: value })} />
      <ToggleSize
        onChange={(event) => handleUpdate({ size: event.target.value })}
      />
      <PainLevel
        onChange={(event) => handleUpdate({ painLevel: event.target.value })}
      />

      <div>
        <h3>Options:</h3>
        <BooleanOption
          label="Blood"
          onChange={(value) => handleUpdate({ blood: value })}
        />

        <BooleanOption
          label="Food Visible"
          onChange={(value) => handleUpdate({ foodVisible: value })}
        />
        <BooleanOption
          label="Bad Smell"
          onChange={(value) => handleUpdate({ badSmell: value })}
        />
        <BooleanOption
          label="Burning"
          onChange={(value) => handleUpdate({ burning: value })}
        />
      </div>
      <div className="actions">
        <Button onClick={onClose}>Cancel</Button>
        <Button
          variant="contained"
          disabled={!isCompleted()}
          onClick={handleSave}
        >
          Save
        </Button>
      </div>
    </div>
  );
};
