import {
  Box,
  Stack,
  Divider,
  Typography,
  Chip,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@mui/material';
import { BRISTOL } from '../../../configs';
import './SlimMovementCard.css';
import { RenderPain } from '../MovementCard/Displays/RenderPain';

import { useState } from 'react';
export const SlimMovementCard = ({ movement }) => {
  const {
    dateCreated,
    bristol,
    color,
    blood,
    painLevel,
    size,
    foodVisible,
    badSmell,
    burning,
  } = movement;
  const bristolObj = BRISTOL[Number(bristol) - 1];
  const day = new Date(dateCreated).toLocaleDateString('en');
  const time = new Date(dateCreated).toLocaleTimeString('en');
  const [timeStr, AMPM] = time.split(' ');
  const hasAttributes = blood || foodVisible || burning || badSmell;
  const movDate = new Date(movement.dateCreated);

  // Accordion
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <Box sx={{ width: '100%' }}>
      <Accordion
        elevation={0}
        expanded={expanded === 'panel1'}
        onChange={handleChange('panel1')}
      >
        <AccordionSummary aria-controls="panel1bh-content" id="panel1bh-header">
          <Stack
            direction="row"
            // divider={<Divider orientation="vertical" flexItem />}
            spacing={2}
            alignItems="center"
          >
            <img
              style={{ width: '50px', height: '50px' }}
              src={bristolObj.img}
            />
            <Typography>
              {timeStr.slice(0, -3)} {AMPM}
            </Typography>

            <Chip label={size} />
            <Typography>
              {movDate.getMonth() + 1}/{movDate.getDate()}
            </Typography>
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          <Stack spacing={2}>
            <span className="bristol-description">
              {bristolObj.description}
            </span>
            <RenderPain painLevel={painLevel} />
            {!hasAttributes && <h6>No remarkable attributes</h6>}
            {hasAttributes && <h4>Attributes:</h4>}
            <div className="movement-attributes">
              {blood && (
                <div className="movement-attr">
                  <span className="attr-icon">🩸</span>{' '}
                  <span className="attr-label">Bloody</span>
                </div>
              )}
              {foodVisible && (
                <div className="movement-attr">
                  <span className="attr-icon">🌽</span>{' '}
                  <span className="attr-label">Visible Food</span>
                </div>
              )}
              {badSmell && (
                <div className="movement-attr">
                  <span className="attr-icon">👃</span>{' '}
                  <span className="attr-label">Bad Smell</span>
                </div>
              )}
              {burning && (
                <div className="movement-attr">
                  <span className="attr-icon">🔥</span>{' '}
                  <span className="attr-label">Burning</span>
                </div>
              )}
            </div>
          </Stack>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default function ControlledAccordions() {
  return <div></div>;
}
