import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

export const BooleanOption = ({ label, value, onChange }) => {
  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Checkbox onChange={(event) => onChange(event.target.checked)} />
        }
        label={label}
      />
    </FormGroup>
  );
};
