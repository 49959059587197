import * as React from "react";
import { Slider } from "@mui/material";

export default function PainLevel({ onChange }) {
  return (
    <div style={{ marginTop: "30px" }}>
      <h3 style={{ marginBottom: "30px", marginTop: "0px" }}>
        Abdominal Pain:
      </h3>
      <Slider
        aria-label="Pain Level"
        defaultValue={1}
        valueLabelDisplay="on"
        onChange={onChange}
        step={1}
        min={1}
        max={5}
      />
    </div>
  );
}
