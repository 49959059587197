export const RenderPain = ({ painLevel }) => {
  const getString = () => {
    switch (painLevel) {
      case "1":
        return "😊 No pain or cramps";
      case "2":
        return "🙂 No pain, a cramp here or there";
      case "3":
        return "😐 Some pain with light cramps";
      case "4":
        return "🤕 Painful abdominal cramps";
      case "5":
        return "🤬 Exceptionally painful cramping";
    }
  };

  return (
    <div
      className="pain-level"
      style={painLevel === "1" ? { color: "green" } : {}}
    >
      {getString(painLevel)}
    </div>
  );
};
