import bristol1 from "../assets/bristol/bristol-01.png";
import bristol2 from "../assets/bristol/bristol-02.png";
import bristol3 from "../assets/bristol/bristol-03.png";
import bristol4 from "../assets/bristol/bristol-04.png";
import bristol5 from "../assets/bristol/bristol-05.png";
import bristol6 from "../assets/bristol/bristol-06.png";
import bristol7 from "../assets/bristol/bristol-07.png";

export const BRISTOL = [
  {
    img: bristol1,
    type: "constipation",
    description: "Separate hard lumps",
  },
  {
    img: bristol2,
    type: "constipation",
    description: "Sausage-shaped but firm and lumpy",
  },
  {
    img: bristol3,
    type: "normal",
    description: "Thicker but soft, with cracks on the surface",
  },
  {
    img: bristol4,
    type: "normal",
    description: "Smooth, soft, uniform",
  },
  {
    img: bristol5,
    type: "stool lacks fiber",
    description: "Soft blobs with clear-cut edges",
  },
  {
    img: bristol6,
    type: "Diarrhea Stool",
    description: "Fluffy, mushy consistency with ragged edges",
  },
  {
    img: bristol7,
    type: "Diarrhea Stool",
    description: "Watery, liquid with no solid pieces",
  },
];
