import React, { useState } from "react";
import { Login } from "../Login/Login";
import { AuthApp } from "../AuthApp/AuthApp";
import { View } from "./View";

function App() {
  const [user, setUser] = useState(null);
  const [tokens, setTokens] = useState(null);

  const handleLogin = (userData) => {
    const { user, tokens } = userData;
    setUser(user);
    setTokens(tokens);
  };

  return (
    <View>
      {!user && <Login onLogin={handleLogin} />}
      {user && <AuthApp user={user} />}
    </View>
  );
}

export default App;
