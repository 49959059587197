import * as React from "react";
import { Select, FormControl, MenuItem, InputLabel, Box } from "@mui/material";

export function ColorChoice({ onChange }) {
  const [choice, setChoice] = React.useState("");

  const handleChange = (event) => {
    const { value } = event.target;
    setChoice(value);
    onChange(value);
  };

  return (
    <div style={{ marginTop: "30px" }}>
      <FormControl fullWidth>
        <InputLabel id="color-choice">Color Choice</InputLabel>
        <Select
          labelId="color-choice"
          id="color-choice-select"
          value={choice}
          label="Color Choice"
          onChange={handleChange}
          required
        >
          <MenuItem value={1}>1</MenuItem>
          <MenuItem value={2}>2</MenuItem>
          <MenuItem value={3}>3</MenuItem>
          <MenuItem value={4}>4</MenuItem>
          <MenuItem value={5}>5</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
}
