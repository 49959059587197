import axios from 'axios';
import { useEffect } from 'react';
import { useState } from 'react';
import { BASE_API_URL } from '../../configs';
import { MovementCard, SlimMovementCard } from '../Movements';
import './Timeline.css';
import background from '../../assets/bathtrack_bg.png';
import { format } from 'date-fns';
import { Button, Divider, Typography } from '@mui/material';

export const Timeline = ({ user, movements, onLoadMore }) => {
  let day;
  if (movements.length) {
    day = new Date(movements[0].dateCreated).getDate();
  }
  const renderMovements = () => {
    return (
      <>
        {movements.map((movement, index) => {
          const movDate = new Date(movement.dateCreated);
          const newDate = movDate.getDate();
          let isNewDay = false;
          let count = 0;
          if (day !== newDate) {
            day = newDate;
            isNewDay = true;
            for (let i = index; i < movements.length; i++) {
              const futureDate = new Date(movements[i].dateCreated).getDate();
              if (newDate === futureDate) {
                count++;
              } else {
                break;
              }
            }
          }
          return (
            <>
              {isNewDay && (
                <>
                  <Divider
                    sx={{
                      border: 'none',
                      backgroundColor: '#dff3fd',
                      width: '100%',
                      height: '2px',
                    }}
                  />
                  <span className="new-day-header">
                    <span className="day-of-week">
                      <span className="date">
                        {movDate.getMonth() + 1}/{movDate.getDate()}
                      </span>{' '}
                      {format(movDate, 'EEEE')}
                    </span>

                    <span className="new-day-header-count">Total: {count}</span>
                  </span>
                </>
              )}
              {/* <MovementCard key={index} movement={movement} /> */}
              <SlimMovementCard movement={movement} />
            </>
          );
        })}
      </>
    );
  };
  return (
    <div
      className="timeline"
      // style={{
      //   backgroundImage: `url(${background})`,
      // }}
    >
      <div className="movement-list">
        <span className="new-day-header">
          <span className="day-of-week">
            <span className="date">
              {new Date().getMonth() + 1}/{new Date().getDate()}
            </span>{' '}
            Today is {format(new Date(), 'EEEE')}
          </span>
        </span>
        {renderMovements()}
        <Button onClick={onLoadMore}>Load more</Button>
      </div>
    </div>
  );
};
