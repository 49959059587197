import "./Toolbar.css";
import logo from "../../assets/bathtrack_192.png";

export const Toolbar = ({ onOpenAddNew }) => {
  const AddNewIcon = () => {
    return (
      <div className="add-new" onClick={onOpenAddNew}>
        <img src={logo} className="toolbar-icon" />
      </div>
    );
  };
  return (
    <div className="toolbar">
      <AddNewIcon />
    </div>
  );
};
