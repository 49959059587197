import { LogoElement } from "../LogoElement/LogoElement";
import "./Header.css";
import { formatDistanceToNow } from "date-fns";

export const Header = ({ lastMovement }) => {
  let timeAgo = "";
  if (lastMovement) {
    timeAgo = formatDistanceToNow(new Date(lastMovement.dateCreated));
  }
  return (
    <header className="header">
      <LogoElement size="small" />
      <span className="time-ago">Last movement was {timeAgo} ago.</span>
    </header>
  );
};
