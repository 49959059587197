import { useState } from "react";
import axios from "axios";
import { BASE_API_URL } from "../../configs";
import "./Login.css";
import { LogoElement } from "../LogoElement/LogoElement";
import { useEffect } from "react";

export const Login = ({ onLogin }) => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [invalid, setInvalid] = useState(false);

  useEffect(() => {
    console.log("Checking if present in local storage...");
    const auth = localStorage.getItem("bathtrackAuth");
    const initialValue = JSON.parse(auth);
    if (!initialValue) return;
    if (initialValue["email"] && initialValue["password"]) {
      setEmail(initialValue["email"]);
      setPassword(initialValue["password"]);
    }
  }, []);
  useEffect(() => {
    setInvalid(false);
  }, [email, password]);

  const login = () => {
    axios
      .post(`${BASE_API_URL}/auth/login`, {
        email,
        password,
      })
      .then(function (response) {
        if (response.status === 200) {
          onLogin(response.data);
        } else {
        }
        console.log(response);
      })
      .catch(function (error) {
        setInvalid(true);
        console.log(error);
        console.log("invalid true");
      });
  };

  const handleClickLogin = () => {
    localStorage.setItem("bathtrackAuth", JSON.stringify({ email, password }));
    login();
  };
  return (
    <div className="login-form">
      <LogoElement />
      <div className={`form-inputs ${invalid ? "invalid" : ""}`}>
        <input
          placeholder="Email"
          value={email}
          required
          onChange={({ target }) => setEmail(target.value)}
        />
        <input
          placeholder="Password"
          value={password}
          type="password"
          required
          onChange={({ target }) => setPassword(target.value)}
        />
        <button
          className="login-button"
          onClick={handleClickLogin}
          disabled={!password || !email}
        >
          Login
        </button>
      </div>
    </div>
  );
};
