import { logo } from "../../assets";
import "./LogoElement.css";

export const LogoElement = ({ size = "default" }) => {
  return (
    <h3 className={`logo-header ${size}`}>
      <img className="logo-img" src={logo} />
      BathTrack
    </h3>
  );
};
