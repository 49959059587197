import { CircularProgress } from "@mui/material";
import "./FullScreenLoader.css";

export const FullScreenLoader = ({}) => {
  return (
    <div className="full-screen-loader">
      <CircularProgress />
    </div>
  );
};
