import * as React from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

export default function ToggleSize({ onChange }) {
  const [alignment, setAlignment] = React.useState("SMALL");

  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
    onChange(event);
  };

  return (
    <>
      <h3 style={{ marginBottom: "10px", marginTop: "10px" }}>Stool Size:</h3>
      <ToggleButtonGroup
        size="large"
        aria-required
        value={alignment}
        exclusive
        onChange={handleAlignment}
        aria-label="text alignment"
      >
        <ToggleButton value="SMALL" aria-label="left aligned">
          Small
        </ToggleButton>
        <ToggleButton value="NORMAL" aria-label="centered">
          Normal
        </ToggleButton>
        <ToggleButton value="LARGE" aria-label="right aligned">
          Large
        </ToggleButton>
      </ToggleButtonGroup>
    </>
  );
}
