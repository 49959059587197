import { useState, useEffect } from 'react';
import { AddNew } from '../AddNew/AddNew';
import { FullScreenLoader } from '../FullScreenLoader/FullScreenLoader';
import { Header } from '../Header/Header';
import { Timeline } from '../Timeline/Timeline';
import { Toolbar } from '../Toolbar/Toolbar';
import axios from 'axios';
import { BASE_API_URL } from '../../configs';

import './AuthApp.css';

export const AuthApp = ({ user }) => {
  const [isAddNewOpen, setIsAddNewOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [movements, setMovements] = useState([]);
  const [lastMovement, setLastMovement] = useState(null);

  const fetchLatestMovements = () => {
    console.log('Fetching new movements...');
    axios.get(`${BASE_API_URL}/movements/${user.id}`).then((res) => {
      const userData = res.data
        .map((item) => {
          item.duration = 'long';
          return item;
        })
        .sort((a, b) => {
          return new Date(b.dateCreated) - new Date(a.dateCreated);
        });
      if (userData) {
        setMovements(userData);
        setLastMovement(userData[0]);
        console.log(userData[0]);
      }
    });
  };
  useEffect(() => {
    fetchLatestMovements();
  }, []);

  const handleOpenAddNew = () => {
    setIsAddNewOpen(true);
  };

  const handleCloseAddNew = () => {
    setIsAddNewOpen(false);
  };

  const handleSaveMovement = (formData) => {
    console.log('Saving movement: ', formData);
    setIsSaving(true);
    axios
      .post(`${BASE_API_URL}/movements`, {
        ...formData,
        userId: user.id,
      })
      .then(function (response) {
        if (response.status === 200) {
          console.log('Posted successfully');
          fetchLatestMovements();
        } else {
        }
        console.log(response);
        setIsSaving(false);
      })
      .catch(function (error) {
        console.log(error);
        setIsSaving(false);
        console.log('invalid true');
      });
    return;
  };

  const [renderCount, setRenderCount] = useState(10);

  const loadMore = () =>
    setRenderCount((prev) => {
      if (prev + 10 >= movements.length) return movements.length;
      return prev + 10;
    });

  return (
    <div className="container">
      <Header lastMovement={lastMovement} />
      {isSaving && <FullScreenLoader />}
      {!isAddNewOpen && !isSaving && (
        <>
          <Timeline
            user={user}
            movements={movements.slice(0, renderCount)}
            onLoadMore={loadMore}
          />
          <Toolbar onOpenAddNew={handleOpenAddNew} />
        </>
      )}

      {isAddNewOpen && (
        <AddNew onClose={handleCloseAddNew} onSave={handleSaveMovement} />
      )}
    </div>
  );
};
