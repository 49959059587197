import * as React from "react";
import { Select, FormControl, MenuItem, InputLabel, Box } from "@mui/material";
import { BRISTOL } from "../../../configs/bristols";
import "./BristolChoice.css";

export function BristolChoice({ onChange }) {
  const [choice, setChoice] = React.useState("");

  const handleChange = (event) => {
    const { value } = event.target;
    setChoice(value);
    onChange(value);
  };

  return (
    <div style={{ marginTop: "30px" }}>
      <FormControl fullWidth>
        <InputLabel id="bristol-choice">Bristol Choice</InputLabel>
        <Select
          labelId="bristol-choice"
          id="bristol-choice-select"
          value={choice}
          label="Bristol Choice"
          onChange={handleChange}
          required
        >
          {BRISTOL.map((bristol, index) => {
            return (
              <MenuItem
                value={index + 1}
                key={index}
                style={
                  index === BRISTOL.length - 1
                    ? {}
                    : { borderBottom: "1px solid #dff3fd" }
                }
              >
                <div className="bristol-menu-item">
                  <img src={bristol.img} />
                  <span>{bristol.description}</span>
                </div>
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
}
